<template>
  <div class="upload-box">
    <div class="table">
      <el-table ref="tables" :data="table" tooltip-effect="dark" style="width: 100%"  @selection-change="selectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column>
          <template slot-scope="scope" ><div>
              <p class="title-name">{{ scope.row.file_name }}</p>
              <span class="table-basic-infor" >updated
                {{ scope.row.updated_at | dateFormat }}</span>
              <span class="table-basic-infor" v-if="scope.row.user_name">Provided by {{ scope.row.user_name }}</span>
            </div></template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <el-popover placement="bottom" width="100" trigger="hover">
              <div style="text-align: left; margin: 0">
                <p>
                  <el-button @click="sendReminderToTenant(scope.row.template_file_uuid)" type="text" icon="el-icon-share" class="file-btn">Share</el-button>
                </p>
                <p>
                  <!-- <a :href="scope.row.url" target="_black" :download="scope.row.url">Download</a> -->
                  <el-button @click="showDisbursementForm(scope.row)" type="text" icon="el-icon-download" class="file-btn">Download</el-button>
                </p>
                <p>
                  <el-button @click="deleteFile(scope.row.template_file_uuid)" type="text" icon="el-icon-delete" class="file-btn">Delete</el-button>
                </p>
              </div>
              <el-button type="text" slot="reference" style="color: #678993" icon="el-icon-more "></el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="10" @number="pagesChanged"></Page>
    <div class="properties-footer">
      <div style="text-align: center">
        <el-radio-group class="row">
          <div class="subset">
            <el-radio-button :label="1" @click.native.prevent="downloadFile()">Download</el-radio-button>
          </div>
          <div class="subset">
            <el-radio-button :label="2" @click.native.prevent="shareShow = true">Share</el-radio-button>
          </div>
        </el-radio-group>
      </div>
    </div>
    <!-- 分享 -->
    <el-dialog title="Share Document" :visible.sync="shareShow" width="600px">
      <div class="share">
        <div class="share-form">
          <el-form :model="data" ref="data">
            <el-form-item prop="ccs" :rules="rules.required">
              <p class="share-title">Recipient</p>
              <el-input v-model="data.ccs" @blur="select" placeholder="Separate multiple email with a semicolon"/>
            </el-form-item>
            <el-form-item prop="subject" :rules="rules.required">
              <p class="share-title">Subject</p>
              <el-input v-model="data.subject" />
            </el-form-item>
            <el-form-item prop="body" :rules="rules.required">
              <p class="share-title">Body</p>
              <el-input v-model="data.body" />
            </el-form-item>
          </el-form>
        </div>

        <div class="btn">
          <el-button class="light-blue-button" @click="shareShow = false">Cancel</el-button>
          <el-button class="primary-button" @click="shareTemplate('data')">Share</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- template_files -->
  </div>
</template>
<script>
import { apiDelTemplate, apiShareTemplate,} from "../../../API/api";
import Page from "../../common/Paging";
export default {
  props: ["category", "table", "total"],
  components: { Page },
  data() {
    return {
      shareShow: false,
      data: {},
      template_files: [],
      rules: {
        required: [ { required: true, message: "this information is required", trigger: ["blur"], }, ],
      },
    };
  },
  methods: {
    selectionChange(val) {
      this.template_files = [];
      if (val.length === 0) {
        this.template_files = [];
      } else {
        val.forEach((item, index) => {
          this.template_files.push(item.template_file_uuid);
        });
      }
    },
    pagesChanged(number) {
      this.$emit("pagesChanged", number);
    },
    deleteFile(id) {
      apiDelTemplate(id)
        .then((res) => {
          this.table.forEach((item, index) => {
            this.$parent.getTemplates();
            // if (item.template_file_uuid == id) {
            //   this.data.splice(index, 1);
            //   return true;
            // }
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.detail);
        });
    },
    // 单个下载文件
    showDisbursementForm(data) {
      // axios.get(data.url).then((res) => {
      // });
      // downloadFile(
      //       data,
      //       data.file_name
      //     );

    },
    downloadFile() {},
    shareFile() {},
    // 分享单个模板
    sendReminderToTenant(id) {
      this.shareShow = true;
      this.template_files = [];
      this.template_files.push(id);
    },
    // 分享多个模板
    shareTemplate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let objs = {
            template_files: this.template_files,
          };
          objs = Object.assign({}, this.data, objs);
          let obj = { recipients: [] };
          this.data.ccs.trim().split(";").forEach((item, index) => {
              obj.recipients.push(item);
            });
          objs = Object.assign({}, objs, obj);
          delete apiShareTemplate(objs)
            .then((res) => {
              this.data.ccs = this.data.subject = this.data.body = "";
              this.shareShow = false;
            })
            .catch((err) => {
              this.$message.success(err.response.data);
            });
        } else {
          return false;
        }
      });
    },
    select() {
      if (!this.data.ccs.trim()) return true;
      let regEmail = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      return this.data.ccs.trim().split(";").every((item, index) => {
          if (item.trim() !== "" && !regEmail.test(item.trim())) {
            this.$message({
              type: "error",
              message: "Please fill in the correct email",
              center: true,
            });
            return false;
          }
          return true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "./TemplateUpload.scss";
.upload-box {
  .table {
    margin-top: 20px;
    border: 0.017857rem solid #ebeef5;
    /deep/ .el-table {
      th {
        display: none;
      }
      .title-name {
        font-family: "Roboto-Bold", sans-serif;
        color: #36425d;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .table-basic-infor {
      font-size: 14px;
      font-family: "Roboto-Light";
      color: #36425d;
      &:first-of-type {
        display: inline-block;
        margin-right: 40px;
      }
    }
  }
  .properties-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 90;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 -10px 20px 0 rgba(17, 61, 71, 0.1);
    .row {
      justify-content: center;
    }
    /deep/ .el-radio-button {
      margin-right: 30px;
      .el-radio-button__inner {
        border: 1px solid #678993;
        border-radius: 4px;
        color: #678993;
        width: 200px;
        font-family: "Roboto-Regular", sans-serif;
        &:hover {
          background: #678993;
          color: #fff;
        }
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        box-shadow: 0 0 0.036364rem 0.036364rem #678993;
      }
    }
    /deep/ .el-radio-button.is-active .el-radio-button__inner,
    /deep/ .el-radio-button.is-focus .el-radio-button__inner {
      background: #678993;
      color: #fff;
    }
  }
  .share {
    .share-form {
      margin-top: 20px;
      text-align: left;
      /deep/ .el-form-item {
        margin-bottom: 10px;
      }
      .share-title {
        margin: 0;
        line-height: 20px;
      }
    }
    .btn {
      text-align: right;
      .el-button {
        width: 100px;
      }
    }
  }
}
</style>