<template>
  <div class="template-nav">
    <div class="move">
      <div v-if="showTab">
        <p class="title">Templates</p>
        <div class="document-top row">
          <span class="document-tip">Documents</span>
          <el-button class="primary-button" @click="createCategory = true">+ New Document</el-button>
        </div>
        <el-divider></el-divider>
        <div v-if="categorysType.length != 0" style="background-color: #fff">
          <div class="cate-row row" v-for="(item, index) in categorysType" :key="index" @click="tabExchange(index, item)">
            <span class="cate-title">{{ item.name }}</span>
            <i class="el-icon-close" @click="openDelDialog(item.template_file_category_id)">
            </i>
          </div>
        </div>
      </div>
      <div v-if="showFile">
        <p class="title title-file" @click="backTemplate">
          <i class="el-icon-back"></i>Back to Templates
        </p>
        <div style="background-color: #fff">
          <div class="row upload-row">
            <span class="upload-tip" v-if="title">{{ title.name }}</span>
            <div class="upload-file">
              <el-upload class="upload-demo" action="action" v-loading="loading" :http-request="uploadFile" :show-file-list="false">
                <span class="uploadbtn" v-if="title">+ Upload {{ title.name }}</span>
              </el-upload>
            </div>
          </div>
          <Upload-File :category="title" :table="data" :total="total" @pagesChanged="pagesChanged"></Upload-File>
        </div>
      </div>
    </div>
    <!-- 创建类别 -->
    <el-dialog title="New Document" :visible.sync="createCategory">
      <div class="category">
        <p class="category-title">Document Name</p>
        <el-input v-model="category" />
        <div class="btn">
          <el-button class="light-blue-button" @click="createCategory = false">Cancel</el-button>
          <el-button class="primary-button" @click="createTemplateCategory">Save</el-button>
        </div>
      </div>
    </el-dialog>
    <DelDialog @del="delTemplateCategory" :title="delTitle" ref="delInfor"></DelDialog>
  </div>
</template>
<script>
import { apiGetTemplatels, apiGetTemplateCategory, apiCreateTempalteFile, apiUploadTempalte, apiCreateTemplateCategory, apiDelTemplateCategory, } from "../../../API/api";
import DelDialog from "../../common/DelDialog";
import UploadFile from "./TemplateUpload";
export default {
  components: { UploadFile, DelDialog },
  props: ["categorysType"],
  data() {
    return {
      //   categorysType: [],
      tab: -1,
      createCategory: false,
      category: "",
      title: "",
      data: [],
      total: 1,
      current: 1,
      limit: 10,
      delTitle: "Are you sure you want to delete this template category?",
      template_file_category_id: "",
      loading: false,
      // move
      showFile: false,
      showTab: true,
    };
  },
  computed: {
    offset() {
      return (this.current - 1) * this.limit;
    },
    query() {
      return {
        offset: this.offset,
        limit: this.limit,
        category: this.title ? this.title.template_file_category_id : "",
      };
    },
  },
  methods: {
    backTemplate() {
      this.showFile = false;
      this.showTab = true;
    },
    pagesChanged(number) {
      this.current = number;
      this.getTemplates();
    },
    tabExchange(index, item) {
      this.tab = index;
      this.title = item;
      this.data = [];
      this.showFile = true;
      this.showTab = false;
      this.getTemplates();
    },
    // 获取模板列表
    getTemplates() {
      apiGetTemplatels(this.query).then((res) => {
        this.data = res.results;
        this.total = res.count;
      });
    },
    // 上传模板
    uploadFile(param) {
      var fileObj = param.file;
      var form = new FormData();
      form.append("file", fileObj);
      this.loading = true;
      apiUploadTempalte(form).then((res) => {
          let data = {
            file: res.object_uuid,
            category: this.title.template_file_category_id,
            self: false,
          };
          apiCreateTempalteFile(data).then((res) => {
              this.current = 1;
              this.getTemplates();
              this.loading = false;
            }).catch((err) => {
              this.loading = false;
              this.$message.error(err.response.data.detail);
            });
        }).catch((err) => {
          this.loading = false;
          this.$message.error(err.response.data.detail);
        });
    },
    // 创建模板分类
    createTemplateCategory() {
      let data = {
        name: this.category,
      };
      apiCreateTemplateCategory(data).then((res) => {
          this.category = "";
          this.createCategory = false;
          // this.categorysType.push(res);
          this.getTemplateCategory();
        }).catch((err) => {
          this.createCategory = false;
          this.$message.error(err.response.data.detail);
        });
    },
    // 获取模板分类
    getTemplateCategory() {
      apiGetTemplateCategory().then((res) => {
        this.categorysType = res.results;
        this.title = res.results[0];
        this.getTemplates();
      });
    },
    openDelDialog(id) {
      this.$refs.delInfor.show = true;
      this.template_file_category_id = id;
    },
    // 删除模板分类
    delTemplateCategory() {
      apiDelTemplateCategory(this.template_file_category_id).then((res) => {
        this.$refs.delInfor.show = false;
        this.getTemplateCategory();
      });
    },
  },
  created() {
    // this.getTemplateCategory();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "../../../css/dialog.scss";
.template-nav {
  .title {
    font-family: "Roboto-Bold", sans-serif;
    font-size: 24px;
    text-align: left;
    margin: 30px 0 20px 0;
  }
  .title-file{font-size: 16px;i{margin-right: 15px;font-weight: 600;}}
  .document-top {
    background-color: #fff;
    justify-content: space-between;
    padding: 14px 10px 10px 20px;
    .document-tip {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 16px;
    }
    .primary-button {
      padding: 0;
      background-color: #fff;
      color: #678993;
      border: none;
    }
  }
  /deep/ .el-divider--horizontal {
    margin: 0;
  }
  .cate-row {
    justify-content: space-between;
    padding: 14px 10px 14px 20px;
    position: relative;
    .cate-title {
      font-size: 14px;
    }
    .el-icon-close {
      font-size: 20px;
      color: #678993;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      border: 1px solid #e1e9ef;
    }
  }
  .upload-row {
    background-color: #fff;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    .upload-tip {
      font-size: 20px;
      font-family: "Roboto-Bold", sans-serif;
    }
    .uploadbtn {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 14px;
      color: #72949d;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .category {
    .category-title {
      text-align: left;
      line-height: 20px;
      margin-bottom: 0;
    }
    .btn {
      text-align: right;
      margin-top: 20px;
      .el-button {
        width: 100px;
      }
    }
  }
  .move {
    display: none;
  }
  /deep/ .el-dialog {
    width: 600px;
  }
  @media (max-width: 992px) {
    .pc {
      display: none;
    }
    .move {
      display: block;
    }
    /deep/ .el-dialog {
      width: 90%;
    }
    .template-row {
      .template-row-left {
        width: 100%;
      }
      .template-row-right {
        display: none;
      }
    }
  }
}
</style>