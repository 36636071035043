<template>
  <div class="template-box">
    <div class="tamplate-top">
      <span class="template-top-title" >Templates</span>
    </div>
    <div class="template-contect">
      <Template-Nav></Template-Nav>
    </div>
  </div>
</template>
<script>
import TemplateNav from "./Component/TemplateNav";
export default {
  components: { TemplateNav },
};
</script>
<style lang="scss" scoped>
.template-box {
  .tamplate-top {
    margin: 30px 0 20px 0;
    text-align: left;
    .template-top-title {
      font-family: "Roboto-Bold", sans-serif;
      font-size: 24px;
    }
  }
  @media (max-width:992px) {
    .tamplate-top{display: none;}
  }
}
</style>